import { NavItem } from "../types/type";

export const drawerWidth = 280;

export const MAX_PAGE = 5;

export const EnglistNoRoot = {
  path: "/tieng-anh-mat-goc",
  pathName: "/englist-no-root",
};
export const EnglistCommunication = {
  path: "/tieng-anh-giao-tiep",
  pathName: "/englist-communication",
};
export const ToeicPreparation = {
  path: "/luyen-thi-toeic",
  pathName: "/toeic-preparation",
};
export const IeltsPreparation = {
  path: "/luyen-thi-ielts",
  pathName: "/ielts-preparation",
};

export const courseInfors = [
  {
    name: "Tiếng anh mất gốc",
    path: EnglistNoRoot.path,
    pathName: EnglistNoRoot.pathName,
  },
  {
    name: "Tiếng anh giao tiếp",
    path: EnglistCommunication.path,
    pathName: EnglistCommunication.pathName,
  },
  {
    name: "Luyện thi TOEIC",
    path: ToeicPreparation.path,
    pathName: ToeicPreparation.pathName,
    menu: [
      {
        id: 1,
        name: "Khoá học TOEIC 450+",
        elementId: "toeic450",
      },
      {
        id: 2,
        name: "Khoá học TOEIC 650+",
        elementId: "toeic650",
      },
    ],
  },
  {
    name: "Luyện thi IELTS",
    path: IeltsPreparation.path,
    pathName: IeltsPreparation.pathName,
    menu: [
      {
        id: 1,
        name: "Khoá học IELTS 0 - 3.0+",
        elementId: "ielts3.0",
      },
      {
        id: 2,
        name: "Khoá học IELTS 3.0 - 5.0+",
        elementId: "ielts5.0",
      },
      {
        id: 3,
        name: "Khoá học IELTS 6.5+",
        elementId: "ielts6.5",
      },
    ],
  },
];
export const navItems: NavItem[] = [
  { name: "Trang chủ", path: "/", pathName: "/" },
  ...courseInfors,
  {
    name: "Test miễn phí",
    path: "/kiem-tra-dau-vao",
    pathName: "/exam/entrace-test",
  },
  {
    name: "Download",
    path: "/tai-tai-lieu",
    pathName: "/download-document",
    isAuth: true,
  },
  {
    name: "Chính sách",
    path: "/chinh-sach-bao-mat",
    pathName: "/privacy-policy",
  },
  { name: "Liên hệ", path: "/lien-he", pathName: "/contact" },
];

export const addressLists = [
  "Cs1: Số 44 ngõ 40, Tạ Quang Bửu, Bách Khoa, Hà Nội",
  "Cs2: Số 9C8 ngõ 261 Trần Quốc Hoàn, Dịch Vọng Hậu, Cầu Giấy",
  "Cs3: Lô 6 Khu biệt thự liền kề 96 Nguyễn Huy Tưởng, Thanh Xuân",
  "Cs4: Số 104 ngõ 40, Tạ Quang Bửu, Bách Khoa, Hà Nội",
  "Cs5: Số 3 ngõ 34 Nguyễn Hoàng, Nam Từ Liêm, Hà Nội",
];

export const SECTION_A = "sectionA";
export const SECTION_B = "sectionB";
export const SECTION_C = "sectionC";
export const SECTION_D = "sectionD";

export const SectionType: Record<string, string> = {
  [SECTION_A]: "A. SECTION A:",
  [SECTION_C]: "B. SECTION B:",
  [SECTION_B]: "C. SECTION C:",
  [SECTION_D]: "D. SECTION D:",
};
